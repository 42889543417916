import React, { useState, useEffect } from "react";
import Logo from "../img/logo_icon.png";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Navbar() {
    const [data, setData] = useState(null);
    const history = useHistory();

    // Active Link Toggle Function

    function ariaNull(elem) {
        elem.map((e, i) => {
            e.ariaCurrent = "";
            e.classList.remove("active-link");
        });
    }

    // Toggle Logic

    function MenuToggler() {
        console.log("this");
    }

    useEffect(() => {
        const MenuLinks = Array.from(document.getElementsByClassName("nav-link"));
        switch (window.location.href.split("/")[3]) {
            case "about":
                ariaNull(MenuLinks);
                MenuLinks[0].ariaCurrent = "page";
                MenuLinks[0].classList.add("active-link");
                break;
            case "osteopathie":
                ariaNull(MenuLinks);
                MenuLinks[1].ariaCurrent = "page";
                MenuLinks[1].classList.add("active-link");
                break;
            default:
                ariaNull(MenuLinks);
        }
        console.log(window.location.href.split("/")[3]);
    }, [data]);

    const Kalender = "https://www.google.com";

    return (
        <nav className="navbar sticky-top navbar-expand-lg px-4 py-2">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand" onClick={() => setData(window.location.href)}>
                    <img className="logo" src={Logo} alt="Ostefemme" />
                </Link>
                <button
                    className="navbar-toggler"
                    // onClick={() => MenuToggler()}
                    // id="toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#menu"
                    aria-controls="navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon d-flex justify-content-center align-items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-list"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                            />
                        </svg>
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="menu">
                    <ul className="navbar-nav mx-auto navbar-centered">
                        <li className="nav-item active pe-md-3">
                            <Link className="nav-link" to="/about" onClick={() => setData(window.location.href)}>
                                Über mich
                            </Link>
                        </li>
                        <li className="nav-item pe-md-3">
                            <Link to="/osteopathie" className="nav-link" onClick={() => setData(window.location.href)}>
                                Osteopathie-Leistungen
                            </Link>
                        </li>
                        <li className="nav-item termin ">
                            <a
                                className="nav-link kalender navWrap"
                                href="https://www.doctolib.de/osteopath/dreieich/sabine-stroh"
                                // onClick={() => setData(window.location.href)}
                            >
                                <i class="ri-calendar-todo-fill m-2"></i>
                                <strong> TERMIN BUCHEN</strong>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                        <a className="nav-link disabled" href="#">
                            Disabled
                        </a>
                    </li> */}
                    </ul>
                    <ul className="navbar-nav mx-auto navbar-right">
                        <li className="nav-item active">
                            <a className="nav-link navWrap" href="#kontakt">
                                <i class="ri-mail-line m-2"></i> Kontakt
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
