import React, { useState, useEffect } from "react";
import Logo from "../img/logo.png";
import Bg from "../img/osteo_sitzend.jpg";
import Uterus from "../img/uterus.svg";
import picOne from "../img/pic1.png";
import mobileOne from "../img/sabinestroh_neu.jpg";
import mobileTwo from "../img/sabinestroh_zwei.jpg";
import mobileThree from "../img/sabinestroh1.jpg";
import { BrowserRouter, Route, Link } from "react-router-dom";
import useScrollSnap from "react-use-scroll-snap";
import Animista, { AnimistaTypes } from "react-animista";
import Contact from "./contact.js";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function About() {
    const [data, setData] = useState(null);

    useEffect(() => {}, []);

    return (
        <section className="about">
            <div className="container-fluid vh-100-calc position-relative scroll-top" id="aboutOne">
                <div className="row h-100 absolute">
                    <ScrollAnimation
                        animateIn="fadeInLeft"
                        animateOut="fadeOutLeft"
                        animateOnce
                        className="col-md-12 col-lg-6 h-100 d-none d-lg-flex flex-column justify-content-center aboutOne"
                    ></ScrollAnimation>
                    <div className="col-md-12 col-lg-6 boxy2 px-5 text-start h-100 d-flex flex-column justify-content-center slide-in-right">
                        <div className="mobileImg">
                            <img src={mobileOne} alt="" />
                        </div>
                        <p>
                            Mein Name ist Sabine Stroh. <br />
                            <br /> Ich bin Heilpraktikerin und Osteopathin, aber auch Gründerin, Unternehmerin, Frau,
                            Mutter, Tochter, Patientin, Kundalini-Yogalehrerin, …
                        </p>

                        <h2 className="phat mb-4  text-dark">Gesundheit bedeutet für mich </h2>
                        <p>
                            Wie manch andere bin ich damit groß geworden Krankheit als etwas zu betrachten, dass über
                            uns hereinbricht und meist in der Regel mit geeigneten Medikamenten behandelt, unter
                            Kontrolle gebracht und geheilt werden kann.
                        </p>
                        <p>
                            Mit der Geburt meines ersten Kindes, dem Alltag als berufstätige Mutter und zahlreichen
                            kleineren und größeren Erkrankungen im Umfeld, der Diskussion um zunehmende Antibiotika
                            Resistenzen in der Gesellschaft erwachte mein Interesse an Gesundheit: <br></br> <br></br>
                            <i>was braucht es um gesund zu sein?</i>
                        </p>
                        <p>
                            Ich lernte, dass sich dahinter mehr verbirgt als die Abwesenheit von Krankheitssymptomen und
                            dass der menschliche Organismus stets darauf ausgerichtet ist den Zustand des Gesundseins
                            aufrecht zu erhalten oder wieder herzustellen. Dafür verfügen wir über ein komplexes System
                            von Selbstheilungskräften. Bis heute sind jedoch weder diese Kräfte noch ihr Zusammenspiel
                            komplett verstanden. Und die verschiedenen Disziplinen der Medizin geben ihr Bestes, um die
                            Geheimnisse zu lüften.
                        </p>
                    </div>
                </div>
                <a className="downBtn" href="#abouttwo">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <div className="container-fluid vh-100-calc position-relative scroll-top" id="abouttwo">
                <div className="row h-100 absolute">
                    {" "}
                    <ScrollAnimation
                        animateIn="fadeInLeft"
                        animateOut="fadeOutLeft"
                        animateOnce
                        className="col-md-12 col-lg-6 px-5 text-start boxy2 h-100 d-flex flex-column justify-content-center"
                    >
                        <div className="mobileImg">
                            <img src={mobileTwo} alt="" />
                        </div>
                        <h2 className="phat mb-4  text-dark">
                            {" "}
                            Als osteopathische Medizinerin berate, behandle und begleite ich Menschen jeden Geschlechts
                            und Alters*{" "}
                        </h2>
                        <p>
                            <Link to="/osteopathie">Die Osteopathie</Link>&nbsp; bietet hierfür ganzheitliche Diagnose-
                            und Therapiekonzepte sowie manuelle Techniken an. Diese leiten sich ab aus einem fundierten
                            Verständnis der Anatomie, Biochemie, Physiologie und Embryologie des menschlichen Organismus
                            und der Umweltfaktoren, die ihn beeinflussen. Dies umfasst auch das Zusammenspiel von
                            Körper, Geist und Seele.{" "}
                        </p>
                        <p>
                            Mein Wissen hierüber habe ich in einem fünfjährigen Vollzeit-Studium am German College of
                            Osteopathic Medicine (GCOM) in Schlangenbad erworben. Als Mitglied im Verband der
                            Osteopathen Deutschland e.V. (VOD) und im Bund Deutscher Heilpraktiker (BDH) tausche ich
                            mich seitdem regelmäßig fachlich mit meinen Kolleginnen aus und nehme verpflichtend an
                            Fortbildungen teil - und sichere so fortlaufend meine Behandlungsqualität.
                        </p>
                        <p>
                            <i>*Kinder ab Kindergartenalter</i>
                        </p>
                        <p>
                            {/* All dies habe ich während meines 5-jährigen, vollzeitigen Studiums am German College of
                            Osteopathy in Schlangenbad (GCOM) verstehen und erspüren gelernt. Als Mitglied im Verband
                            der Osteopathen Deutschland e.V. (VOD) und im Bund Deutscher Heilpraktiker (BDH) tausche ich
                            mich seitdem regelmäßig fachlich mit meinen Kolleginnen aus und nehme verpflichtend an
                            Fortbildungen teil. */}
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateIn="fadeInRight"
                        animateOut="fadeOutRight"
                        animateOnce
                        className="col-lg-6 h-100 d-none d-lg-flex flex-column justify-content-center aboutTwo"
                    ></ScrollAnimation>
                </div>
                <a className="downBtn" href="#aboutthree">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <div className="container-fluid vh-100-calc position-relative scroll-top" id="aboutthree">
                <div className="row h-100 absolute">
                    <ScrollAnimation
                        animateIn="fadeInLeft"
                        animateOut="fadeOutLeft"
                        animateOnce
                        className="col-md-12 col-lg-6 h-100 d-none d-lg-flex flex-column justify-content-center aboutThree"
                    ></ScrollAnimation>
                    <div className="col-md-12 col-lg-6 boxy2 px-5 text-start h-100 d-flex flex-column justify-content-center slide-in-right">
                        <div className="mobileImg">
                            <img src={mobileThree} alt="" />
                        </div>
                        <h2 className="phat mb-4  text-dark"> Als Ihre Therapeutin</h2>
                        <p>
                            mache ich mir zunächst ein möglichst vollständiges Bild von den unterschiedlichen
                            Einflüssen, die Ihre Gesundheit seit Ihrer Entwicklung im Mutterleib bis ins Heute geprägt
                            haben. Dazu führen wir ein ausführliches Anamnese-Gespräch. Im Anschluss schaue ich mir
                            Ihren Körper sehr genau an und untersuche Sie von Kopf bis Fuß, um Strukturen und (Fehl-)
                            Funktionen Ihres Körpers zu erfassen. Hierauf fußen schließlich meine Diagnose und
                            Behandlung.
                        </p>
                        <p>
                            Wie viele Behandlungen erforderlich sind, hängt von Art, Dauer und Umfang der
                            Funktionsstörung und dem gewünschten Therapieziel ab. Grundsätzlich sind für langjährige
                            Beschwerden häufigere Behandlungen erforderlich als für akute Gesundheitsprobleme.
                        </p>
                        {/* <p>
                            Ich lernte, dass sich dahinter mehr verbirgt als die Abwesenheit von Krankheitssymptomen und
                            dass der menschliche Organismus stets darauf ausgerichtet ist den Zustand des Gesundseins
                            aufrecht zu erhalten oder wieder herzustellen. Dafür verfügen wir über ein komplexes System
                            von Selbstheilungskräften. Bis heute sind jedoch weder diese Kräfte noch ihr Zusammenspiel
                            komplett verstanden. Und die verschiedenen Disziplinen der Medizin geben ihr Bestes, um die
                            Geheimnisse zu lüften.
                        </p> */}
                    </div>
                </div>
                <a className="downBtn" href="#aboutfour">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <div className="container-fluid vh-100-calc position-relative scroll-top" id="aboutfour">
                <div className="row h-100 absolute">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce
                        className="col-sm-12 h-100 d-flex flex-column justify-content-center aboutFour"
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-8 text-start boxy">
                                    <h2 className="phat mb-4  text-dark">Osteopathie in Frauenhand</h2>
                                    <p className="">
                                        Als Gründerin von OsteoFemme, Unternehmerin, Medizinerin und Frau möchte ich das
                                        gesellschaftliche Bewusstsein für die - aus medizinischer Sicht - durchaus
                                        gefährliche Ungleichbehandlung von Männern und Frauen schärfen: Unterschiede
                                        zwischen den Geschlechtern werden in der Entwicklung von Medikamenten und
                                        Therapien fast vollständig außen vor gelassen. Auch aktuell sind die Teilnehmer
                                        wissenschaftlicher Studien vorrangig männlich bzw. Studienergebnisse nicht
                                        geschlechtsspezifisch ausgewertet. Dabei unterscheiden sich Krankheitssymptome
                                        und Behandlungsergebnisse oftmals deutlich. Erkrankungen von Frauen bleiben so
                                        länger unerkannt. Frauen-Beschwerden werden eher als psychosomatisch eingestuft,
                                        weil das Untersuchungs-Spektrum nicht ausgeschöpft wird. Medikamente werden
                                        falsch dosiert oder gar falsch verordnet. Mein berufliches Engagement durch
                                        OsteoFemme trägt hoffentlich dazu bei, dass ein Umdenken stattfindet.
                                        {/* Unterschiede
                                        zwischen den Geschlechtern werden in der Entwicklung von Medikamenten und
                                        Therapien vielfach außen vor gelassen. Dabei unterscheiden sich
                                        Krankheitssymptome oftmals deutlich und Erkrankungen von Frauen bleiben so
                                        länger unerkannt. Frauen-Beschwerden werden eher als psychosomatisch eingestuft,
                                        weil das Untersuchungs-Spektrum nicht ausgeschöpft wird. Medikamente werden
                                        falsch dosiert oder gar falsch verordnet. Und leider tragen Frauen bisher noch
                                        immer häufiger als Männer Verantwortung im Beruf, in der Familie und für das
                                        Alltags-Management. Sie sind dadurch oft zu erschöpft, um sich ihrer eigenen
                                        Gesundheit und der Erfüllung ihres individuellen Lebensplans zu widmen. Mein
                                        berufliches Engagement durch OsteoFemme trägt hoffentlich dazu bei, dass ein
                                        Umdenken stattfindet. */}
                                    </p>
                                    <p>
                                        <strong>
                                            Neugierig geworden? Dann vereinbaren Sie noch heute einen{" "}
                                            <a href="https://app.synaptos.at/myapp/smartSchedule/scheduleOnlineBookingCore.jsf?jwt=eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJzeW5hcHRvcyIsImlhdCI6MTYyMjYxODc5MywiYXVkIjoiYmYxMzExM2E4MDU2YmVjOTI4OTM5YTkyIn0.__TdH4RhqzLFslqO9b0OhZLX6K18l7dHuuHYWfYCSwW6E5ryhXWtsuxzn69fneVljJ7HKuCeu7k8rXiH9mbwkA;">
                                                Termin
                                            </a>
                                            .
                                        </strong>
                                    </p>

                                    {/* <a
                                        className="kalender navWrap myBtn red mt-5 white-text"
                                        href="https://app.synaptos.at/myapp/smartSchedule/scheduleOnlineBookingCore.jsf?jwt=eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJzeW5hcHRvcyIsImlhdCI6MTYyMjYxODc5MywiYXVkIjoiYmYxMzExM2E4MDU2YmVjOTI4OTM5YTkyIn0.__TdH4RhqzLFslqO9b0OhZLX6K18l7dHuuHYWfYCSwW6E5ryhXWtsuxzn69fneVljJ7HKuCeu7k8rXiH9mbwkA;"
                                        // onClick={() => setData(window.location.href)}
                                    >
                                        <i class="ri-calendar-todo-fill m-2"></i>
                                        <strong> TERMIN BUCHEN</strong>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                <a className="downBtn" href="#kontakt">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <Contact></Contact>
        </section>
    );
}
