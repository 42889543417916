import React, { useState, useEffect, useRef } from "react";
import Logo from "../img/logo.png";
import Bg from "../img/2021OsteoFemme-Foto15-Visceral-Colonliegend.jpg";
import Uterus from "../img/uterus.svg";
import picOne from "../img/pic1.png";
import { BrowserRouter, Route, Link } from "react-router-dom";
import useScrollSnap from "react-use-scroll-snap";
import Animista, { AnimistaTypes } from "react-animista";
import Contact from "./contact.js";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function Home() {
    const [data, setData] = useState(null);
    // const scrollRef = useRef(null);
    // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });

    function closeMe(elem) {
        elem.style.display = "none";
        console.log(elem);
    }

    useEffect(() => {}, []);

    return (
        <section className="home">
            <div className="container-fluid vh-100-top homeBg fade-in">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-12 col-lg-6 text-start h-100 d-flex flex-column justify-content-center slide-in-left">
                            <div className="slogan mb-4 MontserratBold">
                                <h1>OsteoFemme </h1>
                                <h4>Osteopathie in Frauenhand</h4>
                            </div>
                            {/* <div className="covid px-4 py-5" id="covid">
                                <div id="closeBtnCovid" onClick={() => closeMe(document.querySelector("#covid"))}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                    </svg>
                                </div>
                                <h3 className="d-flex">
                                    <i className="ri-information-line me-3"></i>
                                    Aktuelles
                                </h3>
                                <hr />
                                <p>
                                    <strong>Liebe Patientinnen, liebe Patienten,</strong>
                                </p>
                                <p>
                                    Leben ist Veränderung - und ich habe das große Vergnügen Sie ab dem{" "}
                                    <strong>01. Februar 2023</strong> (ausschließlich) in meiner neuen Praxis unter
                                    folgender Adresse willkommen zu heißen:
                                    <br></br>
                                    <br></br>
                                    Praxis für Osteopathie<br></br> Hainer Trift 25<br></br>
                                    63303 Dreieich - Buchschlag<br></br>
                                    <br></br>
                                    Wie bisher möchte ich Sie bitten, wenn Sie grippeähnliche Symptome,
                                    Erkältungssymptome oder eine andere akut ansteckende Krankheit haben, Ihren Termin
                                    abzusagen.
                                    <br />
                                    <br /> Herzliche Grüße
                                    <br /> Ihre Sabine Stroh
                                </p>
                            </div> */}
                        </div>
                        {/* <div className="col-md-12 col-lg-6 text-start h-100 d-flex flex-column justify-content-center slide-in-left">
   
                            <div className="covid px-4 py-5">
                                <h3 className="d-flex">
                                    <i className="ri-information-line me-3"></i>
                                    Covid Info
                                </h3>
                                <hr />
                                <p>
                                    <strong>Liebe Patientinnen, liebe Patienten,</strong>
                                </p>
                                <p>
                                    ich bin weiterhin für Sie da. Aufgrund der aktuellen Situation und auch
                                    grundsätzlich möchte ich Sie bitten, wenn Sie grippeähnliche Symptome,
                                    Erkältungssymptome oder eine andere akut ansteckende Krankheit haben, den Termin
                                    abzusagen.
                                    <br />
                                    <br /> Herzliche Grüße
                                    <br /> Ihre Sabine Stroh
                                </p>
                            </div>
                        </div> */}
                        <div className="col-6 opaque  text-start h-100 d-flex flex-column justify-content-center slide-in-right text-lightRed">
                            {/* <img src={Uterus} alt="" /> */}
                        </div>
                    </div>
                </div>
                <a className="downBtn" href="#about">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>

            <div className="container-fluid vh-100-calc position-relative scroll-top" id="about">
                <div className="row h-100">
                    <ScrollAnimation
                        animateIn="fadeInLeft"
                        animateOut="fadeOutLeft"
                        animateOnce
                        className="col-md-6 h-100 d-none d-md-flex flex-column justify-content-center homePOne"
                    ></ScrollAnimation>
                    <div className="col-sm-12 col-md-6 px-5 text-start h-100 d-flex flex-column justify-content-center slide-in-right">
                        <h2 className="phat mb-4  text-dark">Mein Beitrag zu Ihrer Gesundheit</h2>
                        <p>
                            Als Osteopathin und Heilpraktikerin ist mein medizinischer Beitrag zu Ihrer Gesundheit
                            bestehende und beginnende Funktionsstörungen zu entdecken, deren Entstehungsgeschichte zu
                            erkunden und Ihrem Körper durch eine geeignete osteopathische Therapie die nötigen Impulse
                            zu geben, sich selbst so zu reorganisieren, dass sich Ihre Selbstheilungskräfte optimal
                            entfalten können und ein Gesundungsprozess einsetzt.
                        </p>
                        <Link to="/about" className="myBtn red mt-5">
                            mehr
                        </Link>
                    </div>
                </div>
                <a className="downBtn" href="#osteopathie">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <div className="container-fluid vh-100-calc position-relative scroll-top mb-5" id="osteopathie">
                <div className="row h-100">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce
                        className="col-sm-12 h-100 d-flex flex-column justify-content-center homePTwo"
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 text-start boxy">
                                    <h2 className="phat mb-4  text-dark">Osteopathie</h2>
                                    <p className="">
                                        Osteopathie ist eine eigenständige, ganzheitliche Form der Medizin, bei der
                                        Diagnostik und Therapie mit den Händen erfolgen. Ziel ist es, Funktionsstörungen
                                        sowie deren Ursachen zu erkennen und so zu behandeln, dass die
                                        Selbstheilungskräfte des Organismus ihre Wirkung voll entfalten und ein
                                        Gesundungsprozess beginnt.
                                    </p>
                                    <Link to="/osteopathie" className="myBtn red mt-5">
                                        mehr
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                <a className="downBtn" href="#kontakt">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <Contact></Contact>
        </section>
    );
}
