import React, { useState, useEffect } from "react";

import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Link } from "react-router-dom";
import "animate.css/animate.min.css";

// COMPONENTS
import Navbar from "./components/navbar";
import Newsletter from "./components/newsletter";
import Home from "./components/home";
import About from "./components/about";
import Osteopathie from "./components/osteopathie";
import Contact from "./components/contact";
import Impressum from "./components/impressum";
import Datenschutz from "./components/datenschutz";
import Partner from "./components/partner";
import DownArrow from "./components/downArrow";
import ScrollToTop from "./components/scrolltotop";
import Footer from "./components/footer";
import Popup from "./components/popup";
import Overlay from "./components/overlay";

import CookieConsent from "react-cookie-consent";

function App() {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <BrowserRouter>
            <div className="App">
                {/* {showPopup && (
                    <>
                        <Overlay></Overlay>
                        <Popup onClick={() => setShowPopup(false)}>
                            <p>
                                <strong>Liebe Patientinnen, liebe Patienten,</strong>
                            </p>
                            <p>aufgrund einer Verletzung bin ich leider erst ab Anfang März wieder für Sie da.</p>
                            <p>Ich bitte um Verständnis.</p>
                        </Popup>
                    </>
                )} */}

                <ScrollToTop />
                <Navbar></Navbar>
                {/* <Newsletter></Newsletter> */}
                {/* <DownArrow></DownArrow> */}
                <Route component={Home} exact path="/"></Route>
                <Route component={Home} path="/osteofemme"></Route>
                <Route component={About} path="/about"></Route>
                <Route component={Impressum} path="/impressum"></Route>
                <Route component={Datenschutz} path="/datenschutz"></Route>
                <Route component={Partner} path="/partner"></Route>
                <Route component={Osteopathie} path="/osteopathie"></Route>
                <CookieConsent
                    location="bottom"
                    buttonText="Ok"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                >
                    Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern.
                    <span style={{ fontSize: "10px", paddingLeft: "10px" }}>
                        Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies gemäß unserer{" "}
                        <a className="text-white" href="/datenschutz">
                            {" "}
                            Datenschutzrichtlinie{" "}
                        </a>{" "}
                        zu."
                    </span>
                </CookieConsent>
                <Footer></Footer>
            </div>
        </BrowserRouter>
    );
}

export default App;
