import React, { useState, useEffect, useRef } from "react";
import Logo from "../img/logo.png";
import Bg from "../img/osteo_sitzend.jpg";
import Uterus from "../img/uterus.svg";
import picOne from "../img/pic1.png";
import mobileOne from "../img/osteo_frauen.jpg";
import mobileTwo from "../img/osteopathie.jpg";
import { BrowserRouter, Route, Link } from "react-router-dom";
import useScrollSnap from "react-use-scroll-snap";
import Animista, { AnimistaTypes } from "react-animista";
import Contact from "./contact.js";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function Osteopathie() {
    const [data, setData] = useState(null);
    // const scrollRef = useRef(null);
    // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });

    useEffect(() => {
        document.querySelector("#viszerale").addEventListener("click", function () {
            document.querySelector(".osteoTwo").classList.remove("cranio");
            document.querySelector(".osteoTwo").classList.remove("parietal");
            document.querySelector(".osteoTwo").classList.add("viszeral");
        });
        document.querySelector("#cranio").addEventListener("click", function () {
            document.querySelector(".osteoTwo").classList.remove("viszeral");
            document.querySelector(".osteoTwo").classList.remove("parietal");
            document.querySelector(".osteoTwo").classList.add("cranio");
        });
        document.querySelector("#parietal").addEventListener("click", function () {
            document.querySelector(".osteoTwo").classList.remove("viszeral");
            document.querySelector(".osteoTwo").classList.remove("cranio");
            document.querySelector(".osteoTwo").classList.add("parietal");
        });
    }, []);

    return (
        <section className="osteopathie">
            <div className="container-fluid vh-100-calc position-relative scroll-top" id="osteoOne">
                <div className="row h-100 absolute">
                    <ScrollAnimation
                        animateIn="fadeInLeft"
                        animateOnce
                        className="col-md-6 h-100 d-none d-md-flex flex-column justify-content-center osteoOne"
                    ></ScrollAnimation>
                    <div className="col-sm-12 col-md-6 boxy2 px-5 text-start h-100 d-flex flex-column justify-content-center slide-in-right">
                        <div className="mobileImg">
                            <img src={mobileOne} alt="" />
                        </div>
                        <h2 className="phat mb-4  text-dark">Osteopathie als komplementäre Medizin</h2>
                        <p>
                            Osteopathie ist eine eigenständige, ganzheitliche Form der Medizin, bei der Diagnostik und
                            Therapie mit den Händen erfolgen. Ziel ist es, Funktionsstörungen sowie deren Ursachen zu
                            erkennen und so zu behandeln, dass die Selbstheilungskräfte des Organismus ihre Wirkung voll
                            entfalten und ein Gesundungsprozess beginnt.
                        </p>
                        <p>
                            Funktionsstörungen ergeben sich nach osteopathischem Verständnis aus
                            Bewegungseinschränkungen im Zusammenspiel von Muskeln, Knochen, Gelenken, Organen, Nerven,
                            Gefäßen, Faszien und anderen Bindegeweben.
                        </p>
                        {/* <div className="hinweis">
                            <p>
                                <strong> Hinweis:</strong> <br />
                                Bitte beachten Sie, dass alle aufgeführten Krankheitsbilder und Diagnosen beispielhaften
                                Charakter tragen und diese Aufzählungen keineswegs den Anspruch auf Vollständigkeit
                                erheben. Diese Angaben sind empirischer Natur und beruhen auf den jahrzehntelangen
                                Erfahrungen vieler Osteopathischer Therapeutinnen*. Sie beinhalten keinerlei
                                Heilversprechen und geben keine Garantie für die Linderung Ihrer individuellen
                                Beschwerden oder Symptome.
                            </p>
                        </div> */}
                    </div>
                </div>
                <a className="downBtn" href="#osteotwo">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            <div className="container-fluid position-relative scroll-top mb-5" id="osteotwo">
                <div className="row">
                    {" "}
                    <ScrollAnimation
                        animateIn="fadeInLeft"
                        animateOnce
                        className="col-md-12 col-lg-6 px-5 text-start h-100 d-flex flex-column justify-content-center pt-neu"
                    >
                        <div className="mobileImg">
                            <img src={mobileTwo} alt="" />
                        </div>
                        <h2 className="phat mb-4  text-dark">Bereiche innerhalb der Osteopathie </h2>
                        <p>
                            Innerhalb der Osteopathie wird zwischen{" "}
                            <strong>craniosacraler, viszeraler und parietaler</strong> Osteopathie unterschieden:
                        </p>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        id="cranio"
                                    >
                                        Craniosacrale Osteopathie
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <p>
                                            Die <strong>craniosacrale Osteopathie</strong> bezieht sich auf den
                                            Schädelbereich und dessen funktionelle Verbindung zum Kreuzbein über Gehirn,
                                            Rückenmark und Gehirnflüssigkeit. Die osteopathische Therapie nutzt diese
                                            Verbindungen, um sehr sanft in die Regulationsmechanismen des Körpers
                                            einzugreifen.
                                        </p>
                                        <p>
                                            <strong>Typische Beschwerden in diesem Bereich sind u.a.:</strong>
                                        </p>

                                        <ul>
                                            <li>Kopfschmerzen oder Migräne</li>
                                            <li>Schlafstörungen und innere Unruhe</li>
                                            <li>Infektanfälligkeit</li>
                                            <li>Zähneknirschen</li>
                                            <li>Schwindel</li>
                                            <li>Tinnitus</li>
                                            <li>Sehstörungen</li>
                                            <li>Vegetative Störungen, z.B. durch Stress oder Burn-Out Syndrom</li>
                                            <li>
                                                Wiederkehrende oder chronische Entzündungen in Ohr oder Nasennebenhöhlen
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                        id="viszerale"
                                    >
                                        Viszerale Osteopathie
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <p>
                                            Die <strong> viszerale Osteopathie</strong> beschäftigt sich mit der
                                            Behandlung der inneren Organe und ihren umgebenden, bindegewebigen
                                            Strukturen. Störungen in diesem Bereich sind häufig unspezifisch und schwer
                                            einzuordnen. Die Osteopathie kennt hierfür spezielle Diagnosemethoden und
                                            Therapiemaßnahmen.
                                        </p>
                                        <p>
                                            <strong>Typische Beschwerden in diesem Bereich sind u.a.:</strong>
                                        </p>

                                        <ul>
                                            <li>
                                                Narben und Verwachsungen, z.B. nach Blinddarm-Entfernung oder
                                                Kaiserschnitt
                                            </li>
                                            <li>Verdauungsstörungen oder Sodbrennen</li>
                                            <li>Atemnot</li>
                                            <li>Asthma</li>
                                            <li>Menstruations-Probleme</li>
                                            <li>Unerfüllter Kinderwunsch</li>
                                            <li>Schmerzen beim Geschlechtsverkehr</li>
                                            <li>Inkontinenz</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                        id="parietal"
                                    >
                                        Parietale Osteopathie
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <p>
                                            Die <strong>parietale Osteopathie </strong>behandelt den gesamten
                                            Bewegungsapparat, d.h. alle Gewebestrukturen, die wir für unsere körperliche
                                            Bewegung brauchen. Das sind insbesondere Gelenke, Muskeln, Sehnen/Bänder und
                                            Faszien sowie das Skelett.
                                        </p>
                                        <p>
                                            <strong>Typische Beschwerden in diesem Bereich sind u.a.:</strong>
                                        </p>
                                        <ul>
                                            <li>Verspannungen und Muskelschmerzen - chronisch oder akut</li>
                                            <li>Bewegungseinschränkungen</li>
                                            <li>Bandscheibenvorfälle und deren Auswirkungen</li>
                                            <li>Hüft-, Knie-, Fuß-, Schulter-, Ellenbogen-, Handgelenk-Probleme</li>
                                            <li>Schleudertrauma</li>
                                            <li>Impingement-Syndrom</li>
                                            <li>Neuralgien, z.B. Lumboischialgie</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                    >
                                        <strong>Osteopathie im Kontext anderer Medizinischer Disziplinen</strong>
                                    </button>
                                </h2>
                                <div
                                    id="collapseFour"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <p>
                                            Osteopathie versteht sich als Ergänzung zur klassischen Schulmedizin,
                                            ersetzt diese aber nicht. So ist für die vorgenannten Beschwerden Beispiele
                                            in aller Regel eine Abstimmung mit Fachärztinnen erforderlich.
                                        </p>
                                        <p>
                                            Eine osteopathische Behandlung kann als erste therapeutische Maßnahme
                                            stattfinden oder auch unterstützend oder begleitend zu anderen medizinischen
                                            Therapien durchgeführt werden. Oberstes Ziel jeder Zusammenarbeit ist es,
                                            Patientinnen eine auf ihre individuellen Bedürfnisse abgestimmte,
                                            zielgerichtete Behandlung zukommen zu lassen.
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="kontext">
                            <strong className="d-block pb-3 pt-4">
                                Osteopathie im Kontext anderer Medizinischer Disziplinen
                            </strong>

                            <p>
                                Osteopathie versteht sich als Ergänzung zur klassischen Schulmedizin, ersetzt diese aber
                                nicht. So ist für die vorgenannten Beschwerden Beispiele in aller Regel eine Abstimmung
                                mit Fachärztinnen erforderlich.
                            </p>
                            <p>
                                Eine osteopathische Behandlung kann als erste therapeutische Maßnahme stattfinden oder
                                auch unterstützend oder begleitend zu anderen medizinischen Therapien durchgeführt
                                werden. Oberstes Ziel jeder Zusammenarbeit ist es, Patientinnen eine auf ihre
                                individuellen Bedürfnisse abgestimmte, zielgerichtete Behandlung zukommen zu lassen.
                            </p>
                        </div>
                        <div className="hinweis">
                            <p>
                                <strong> Hinweis:</strong> <br />
                                Bitte beachten Sie, dass alle aufgeführten Krankheitsbilder und Diagnosen beispielhaften
                                Charakter tragen und diese Aufzählungen keineswegs den Anspruch auf Vollständigkeit
                                erheben. Diese Angaben sind empirischer Natur und beruhen auf den jahrzehntelangen
                                Erfahrungen vieler Osteopathischer Therapeutinnen. Sie beinhalten keinerlei
                                Heilversprechen und geben keine Garantie für die Linderung Ihrer individuellen
                                Beschwerden oder Symptome.
                            </p>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                        id="osteoTwo"
                        animateIn="fadeInRight"
                        animateOnce
                        className="col-lg-6 d-none d-lg-flex flex-column justify-content-center osteoTwo"
                    >
                        <div className="buffer"></div>
                    </ScrollAnimation>
                </div>
                <a className="downBtn" href="#kontakt">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div>
            {/* <div className="container-fluid vh-100-calc position-relative scroll-top" id="osteothree">
                <div className="row h-100">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        className="col-sm-12 h-100 d-flex flex-column justify-content-center osteoThree"
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 text-start boxy">
                                    <h2 className="phat mb-4  text-dark">Gesundheit aus Frauenperspektive</h2>
                                    <p className="">Ich behandle Frauen, Männer und Diverse.</p>
                                    <p>
                                        Meine ganz besondere Leidenschaft gilt dabei der Frauengesundheit, die ich in
                                        unserer Gesellschaft als besonders gefährdet einschätze: Medizinische Forschung
                                        konzentriert sich noch immer auf die männliche Norm mit der Folge, dass
                                        Geschlechts-spezifische Unterschiede in Krankheitsbildern und Einsatz von
                                        Medikamenten oftmals unberücksichtigt bleiben. Auch das vermeintliche Klischee
                                        der durch Beruf und Familie doppelt-belasteten Frauen ist in meiner Beobachtung
                                        sehr häufig Realität.
                                    </p>
                                    <p>
                                        Nicht zuletzt kenne ich mich nicht nur professionell sondern auch als Mensch
                                        besonders gut mit Frauengesundheit aus - und dieses Wissen möchte ich Ihnen für
                                        Ihre Gesundheit beratend, behandelnd und begleitend zur Verfügung stellen.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                <a className="downBtn" href="#kontakt">
                    <i className="ri-arrow-down-s-line"></i>
                </a>
            </div> */}
            <Contact></Contact>
        </section>
    );
}
