import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

export default function Partner() {
    const [data, setData] = useState(null);

    useEffect(() => {}, []);

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col mt-3 mb-5">
                    <h2 className="mb-5">Partner</h2>
                    <p>
                        {" "}
                        <strong>Dr. med. Regina Heilmann-Seipelt</strong> <br /> Fachärztin für Allgemeinmedizin -
                        Naturheilverfahren <br />
                        Telefon: 06103 676 02 <br />
                        E-Mail: praxis@heilmann-seipelt.de <br />
                        Internet:
                        <a href="https://www.naturheilverfahren-heilmann.de">
                            https://www.naturheilverfahren-heilmann.de
                        </a>
                        {/* Internet: <a href="https://www.physiocomplete.fit"> https://www.physiocomplete.fit</a> */}
                    </p>
                    <p>
                        {" "}
                        <strong>PhysioComplete GmbH</strong> <br /> Am Weingarten 25 <br />
                        60487 Frankfurt <br />
                        Telefon: 069 71589555 <br />
                        E-Mail: info@physiocomplete.fit <br />
                        Internet: <a href="https://www.physiocomplete.fit"> https://www.physiocomplete.fit</a>
                    </p>
                    {/* <p className="mt-5">
                        {" "}
                        <strong>Praxis für Osteopathie</strong> <br /> Julia Ferdinand <br />
                        Am Lachengraben 7 <br />
                        63303 Dreieich <br />
                        Telefon: 06103 - 57 31 30 <br />
                        E-Mail: praxis@julia-ferdinand.de <br />
                        Internet: <a href="https://www.julia-ferdinand.de"> https://www.julia-ferdinand.de</a>
                    </p> */}
                    <p className="mt-5">
                        {" "}
                        <strong> Filizbewegt - Personal Training, Food Coach</strong> <br /> Filiz Senner <br />
                        Dreieichring 38
                        <br />
                        63067 Offenbach am Main <br />
                        Telefon: 0173 3049229 <br />
                        E-Mail: hallo@filizbewegt.de <br />
                        Internet: <a href="https://www.filizbewegt.de"> https://www.filizbewegt.de</a>
                    </p>
                    <h4 className="mb-4 mt-5">Herzlichen Dank an:</h4>
                    <h4>
                        <strong>Fotos</strong>{" "}
                    </h4>
                    <p className="mt-3">
                        {" "}
                        <strong> WesenArt</strong> <br /> Björn Langheim <br />
                        Georgenstraße 6a <br />
                        61231 Bad Nauheim <br />
                        Telefon: 0176 81379814 <br />
                        E-Mail: foto@wesenart.com <br />
                        Internet: <a href="https://wesenart.com"> https://wesenart.com</a>
                    </p>
                    <h4 className="mt-5">
                        <strong>Gestaltung Website und Außenauftritt</strong>{" "}
                    </h4>
                    <p className="mt-3">
                        {" "}
                        <strong> HL Agencies / Sabocon</strong> <br /> Lena Sophie Werner <br />
                        Telefon: +49 172 - 5354410 <br />
                        Internet: <a href="https://www.sabocon.com"> https://www.sabocon.com</a>
                    </p>
                    <h4 className="mt-5">
                        <strong>Praxissoftware</strong>{" "}
                    </h4>
                    <p className="mt-3">
                        {" "}
                        <strong> Synaptos GmbH</strong> <br />
                        Hans Sachs Straße 6/2/4 <br />
                        9020 Klagenfurt am Wörthersee <br />
                        Telefon: +43 463 289992 <br />
                        E-Mail: office@synaptos.at <br />
                        Internet: <a href="https://synaptos.at"> https://synaptos.at</a>
                    </p>
                </div>
            </div>
        </div>
    );
}
