import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

export default function Impressum() {
    const [data, setData] = useState(null);

    useEffect(() => {}, []);

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col mt-3 mb-5 text-start">
                    <h2 className="mb-5">Impressum</h2>
                    {/* <p>Angaben gemäß § 5 TMG:</p> */}
                    <p>
                        <h4>
                            <strong>Sabine Stroh</strong>
                        </h4>
                    </p>
                    <p>
                        <strong>OsteoFemme </strong>
                    </p>
                    <p>
                        <strong> Praxis für Osteopathie:</strong>
                        <br />
                        Hainer Trift 25 <br /> 63303 Dreieich - Buchschlag
                        <br />
                        {/* <strong> Praxis Standort 2:</strong> <br /> Am Weingarten 25,
                        <br /> 60487 Frankfurt <br />
                        <br />
                        /o PhysioComplete
                        <br /> */}
                    </p>
                    <p>
                        <strong> Kontakt:</strong> <br></br>Telefon: +49 176 2015 8693<br></br>
                        E-Mail: sabine.stroh@osteofemme.de
                    </p>
                    <p>
                        <strong>Verantwortlich für die Website (gem. § 55 Abs. 2 RStV): </strong>
                        <br />
                        Sabine Stroh
                    </p>
                    <p>
                        <strong> Berufsbezeichnung und Berufsrechtliche Regelungen: </strong> <br />
                        <ul>
                            <li>Heilpraktikerin</li>
                            <li>
                                {" "}
                                Amtsärztliche Überprüfung und Behördliche Erlaubnis durch Gesundheitsamt Kreis Offenbach
                            </li>
                        </ul>
                    </p>
                    <p>
                        {" "}
                        <strong> Rechtsgrundlage der Heilpraktiker Tätigkeit:</strong> <br />
                        Heilpraktikergesetz und Durchführungsverordnung, nachzulesen im Internet unter: <br />
                        <a href="http://www.gesetze-im-internet.de/heilprg/index.html">
                            http://www.gesetze-im-internet.de/heilprg/index.html
                        </a>
                        und <br />
                        <a href=" http://www.gesetze-im-internet.de/heilprgdv_1/index.html">
                            http://www.gesetze-im-internet.de/heilprgdv_1/index.html
                        </a>
                    </p>
                    <p>
                        <strong> Zuständige Aufsichtsbehörden:</strong>
                        <br />
                        Gesundheitsamt Kreis Offenbach (für Praxis Standort 1)<br></br> Gesundheitsamt Frankfurt (für
                        Praxis Standort 2)
                    </p>
                    <p>
                        <strong> Berufshaftpflichtversicherung:</strong>
                        <br />
                        Continentale Sachversicherung AG, Dortmund - Geltungsraum: Deutschland
                    </p>
                    <p>
                        <strong> Berufsverbandsmitgliedschaften:</strong>
                        <br />
                        <ul>
                            <li> Verband der Osteopathen Deutschland VOD e.V., Mitgliedsnummer VOD: 2144896059 </li>
                            <li>Bund Deutscher Heilpraktiker BDH e.V., Mitgliedsnummer BDH: 200 151</li>
                        </ul>
                    </p>
                    <p>
                        <strong>Verbraucher­streit­beilegung / Universal­schlichtungs­stelle</strong>
                        <br />
                        Ich begrüße jede Interessierte* auf meiner Website. Ich bin jedoch nicht bereit oder
                        verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                    {/* <p>
                        <strong>Haftung für Inhalte</strong>
                        <br></br> Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                        nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieter
                        jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    </p> */}
                    <p>
                        {" "}
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                        der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werde ich diese Inhalte umgehend entfernen.
                    </p>
                    <p>
                        <strong>Haftung für Links</strong>
                        <br /> Mein Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte ich keinen
                        Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                        erkennbar.
                    </p>
                    <p>
                        {" "}
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist ohne konkrete Anhaltspunkte
                        einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich
                        derartige Links umgehend entfernen.{" "}
                    </p>
                    <p>
                        <strong>Urheberrecht</strong>
                        <br /> Sämtliche durch mich erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen meiner schriftlichen Zustimmung.
                        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
                        gestattet.
                    </p>
                    <p>
                        {" "}
                        Soweit die Inhalte auf dieser Seite nicht von mir erstellt wurden, werden die Urheberrechte
                        Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                        trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen entsprechenden
                        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte umgehend
                        entfernen.
                    </p>

                    <p>
                        <strong>Hinweis</strong>
                        <br /> Keine Abmahnung ohne vorherigen Kontakt! Ich werde ohne vorherige Kontaktaufnahme von
                        Ihnen ausgelöste Kosten vollumfänglich zurückweisen und gegebenenfalls Gegenklage wegen
                        Verletzung vorgenannter Bestimmungen einreichen. Ziel dieses Hinweises ist nicht, keine
                        Abmahnung (z.B. eine formlose E-Mail oder ein Telefonat) zu erhalten, sondern nicht mit den
                        Kosten einer anwaltlichen Abmahnung belastet zu werden.
                    </p>

                    {/*                     
 * Gilt für Frauen, Männer und Diverse
                    gleichermaßen *************************** Und folgender Hinweis muss noch in die Seite integriert
                    werden: Hinweis: Aus rechtlichen Gründen weise ich explizit darauf hin, dass alle aufgeführten
                    Krankheitsbilder und Diagnosen beispielhaften Charakter tragen und diese Aufzählungen keineswegs den
                    Anspruch auf Vollständigkeit erheben. Diese Angaben sind empirischer Natur und beruhen auf den
                    jahrzehntelangen Erfahrungen vieler Therapeuten. Sie enthalten keinerlei Heilversprechen oder eine
                    Garantie zur Linderung der Beschwerden oder Symptome. *********************** Eine potentielle
                    Einleitung zum Newsletter „Gesundheit aus Frauenperspektive": Seit April 2021 gibt es den ersten (!)
                    Lehrstuhl für Geschlechtersensible Medizin in Deutschland. (Verlinken:
                    https://aktuell.uni-bielefeld.de/2021/04/21/professur-zu-geschlechtersensibler-medizin/)
                    'Geschlechtersensible Medizin' umfasst sowohl die biologisch-naturwissenschaftlichen Aspekte ( 'sex'
                    ) als auch die sozio-kulturellen ( 'gender' ) sowie die personellen, d.h. die möglichen
                    Geschlechterkonstellationen von Patientinnen, Patienten, Ärztinnen, Ärzten, Lehrenden und
                    Studierenden. Auch international gibt es zwar viele Absichtserklärungen und gesetzliche
                    Rahmenbedingungen zur zwischen den Geschlechtern gleichberechtigten Forschung und medizinischen
                    Behandlung, aber die Umsetzung erfolgt leider schleppend. Feministische Frauenbewegungen im In- und
                    Ausland bzw. national und international mahnen seit den 1970’er Jahren und somit seit bald einem
                    halben Jahrhundert gleichberechtigte medizinische Auseinandersetzung an. Hieraus resultieren sowohl
                    Impetus als auch einige der spannenderen Erkenntnisse. In dieser Rubrik werde ich künftig positive
                    und kritische Auseinandersetzungen mit den Spezifika von Frauengesundheit in Form eines Newsletters
                    aufgreifen. Gerne können Sie sich hierdafür anmelden. */}
                </div>
            </div>
        </div>
    );
}
