import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./style/main.css";
import "./style/mobile.css";
import "./fonts/Montserrat-Bold.ttf";
import "./fonts/Montserrat-Medium.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-Thin.ttf";
import "./fonts/Montserrat-Black.ttf";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/scrolltotop";

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <ScrollToTop />
            <App />
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
