import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Logo_Icon from "../img/logo_icon_only.png";

export default function Footer() {
    const [data, setData] = useState(null);

    useEffect(() => {}, []);

    return (
        <div className="container my-5">
            <div className="row">
                <hr />
                <div className="col footerCol mt-3 mb-5">
                    <Link to="./">
                        <img id="footerImg" src={Logo_Icon} alt="" />
                    </Link>
                    <Link to="/impressum">Impressum</Link>
                    <Link to="/datenschutz">Datenschutzerklärung</Link>
                    <Link to="/partner">Partner</Link>
                </div>
            </div>
        </div>
    );
}
