import React from "react";

const Popup = (props) => {
    return (
        <div
            className={`popupWrapper bg-white position-fixed p-5 ${props.wrapperKlasse}`}
            style={{ zIndex: 1250, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        >
            <div
                className="closer position-absolute"
                style={{ right: "1rem", top: "0.75rem", fontSize: "1.75rem" }}
                onClick={props.onClick}
            >
                <i class="ri-close-circle-fill"></i>
            </div>

            <div className="popupBox">{props.children}</div>
        </div>
    );
};

export default Popup;
