import React, { useState, useEffect } from "react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from "react-google-maps";
import mapStyle from "./mapstyle";
import MyMap from "./maps.js";
import Form from "./form.js";
import face from "../img/pic1.png";
import logo_bg from "../img/logo_bg.png";

const Contact = () => {
    // MAP STUFF

    return (
        <div className="container-fluid" id="kontaktWrapper">
            <div className="container position-relative d-flex flex-column justify-content-center " id="kontakt">
                <div className="row mb-5 order-2">
                    <div className="col">{/* <MyMap></MyMap> */}</div>
                </div>
                <div className="row order-3 text-start">
                    <div className="col-lg-6 order-md-1 mb-5 pt-4">
                        {/* <Form></Form> */}
                        <span className="d-block mb-3">
                            <strong>Kosten:</strong>
                        </span>
                        <p>Meine Leistungen berechne ich nach der Gebührenordnung für Heilpraktiker (GebüH).</p>
                        {/* <p>
                                    Frankfurt:<br></br>
                                    freitags 9.00 - 18.00 Uhr
                                </p> */}
                        <p className="font-weight-bold">
                            <strong> Ersttermin (90 Min.): ca. 110,- € </strong> <br></br>
                            <strong> Folgetermine (60 Min.): ca. 95,- €</strong>
                        </p>
                        <p>
                            Der Behandlungsvertrag kommt zwischen Ihnen und OsteoFemme zustande. Entsprechend wird mit
                            jeder Behandlung der volle Rechnungsbetrag fällig und ist von Ihnen an OsteoFemme zu zahlen.{" "}
                        </p>
                        <p>
                            Zahlungsmöglichkeiten sind Bar- / Kartenzahlung (Debit- / Kredit), Apple Pay oder
                            Überweisung.
                        </p>
                        <p>
                            Die meisten gesetzlichen und viele private Krankenversicherungen übernehmen einen Teil der
                            Kosten. Allerdings unterscheiden sich Beträge und Anzahl der abrechenbaren Behandlungen
                            zwischen den einzelnen Kassen. Für Details wenden Sie sich daher bitte an Ihren
                            Versicherungsträger.
                        </p>
                        <p>
                            OsteoFemme wird nach einem Bestellsystem geführt. Dies bedeutet, dass die vereinbarte Zeit
                            ausschließlich für die jeweilige Patientin reserviert ist. Sollten Sie einen Termin nicht
                            wahrnehmen können, so ist dieser mindestens 24 Stunden vorher abzusagen. Erfolgt keine
                            rechtzeitige Absage, wird die Zahlung des vollen Rechnungsbetrags fällig.{" "}
                        </p>

                        <p>Statt zu Gendern wurde auf dieser Website durchgängig die weibliche Sprachform verwendet </p>
                    </div>
                    <div className="col-lg-6 text-start px-5 pt-3 mb-5">
                        <h2 className="text-left">Sabine Stroh</h2>
                        <h4>Heilpraktikerin - Osteopathie</h4>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12 col-md-6 d-flex">
                                <i class="ri-home-4-line me-3"></i>
                                <p>
                                    Hainer Trift 25
                                    <br />
                                    63303 Dreieich - Buchschlag
                                    <br />
                                    <br /> Praxis für Osteopathie
                                </p>
                            </div>
                            <div className="col-s-12 col-md-6 ">
                                <div className="d-flex ">
                                    <i class="ri-phone-fill me-3"></i>
                                    <p>
                                        <strong>0176 / 20 15 86 93</strong>
                                    </p>
                                </div>
                                <div className="d-flex ">
                                    <i class="ri-mail-fill me-3"></i>
                                    <p>
                                        <strong>sabine.stroh@osteofemme.de</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className="row">
                            <div className="col-sm-12 col-md-6 d-flex">
                                <i class="ri-home-4-line me-3"></i>
                                <p>
                                    Am Lachengraben 7
                                    <br />
                                    63303 Dreieich
                                    <br />
                                    <br /> c/o Praxis f. Osteopathie
                                </p>
                            </div>
                        </div> */}
                        <div className="row">
                            <hr />
                            <div className="col-12">
                                <span className="d-block mb-3">
                                    <strong>Termine:</strong>
                                </span>
                                {/* <p>
                                    Dreieich:<br></br>
                                    montags und donnerstags 9.00 - 18.00 Uhr
                                </p> */}
                                {/* <p>
                                    Frankfurt:<br></br>
                                    freitags 9.00 - 18.00 Uhr
                                </p> */}
                                <p>
                                    Ersttermin: 90 Minuten<br></br>
                                    Folgetermine: 60 Minuten
                                </p>
                                <p className="mb-5">
                                    Zeit = Anamnese, Untersuchung, Behandlung, Umkleiden, Terminabsprache<br></br>
                                    <br></br>
                                    Parkplatz und barrierefreier Zugang verfügbar
                                </p>
                                <a href="https://www.doctolib.de/osteopath/dreieich/sabine-stroh">
                                    <button
                                        id="submit"
                                        type="submit"
                                        value="abschicken"
                                        // disabled="true"
                                        className="myBtn red big mb-4 mt-6"
                                    >
                                        Termin buchen
                                    </button>{" "}
                                </a>
                            </div>
                            {/* <div className="col-6">
                            <i class="ri-facebook-circle-fill me-3"></i>
                            <i class="ri-instagram-fill me-3"></i>
                            <i class="ri-twitter-fill me-3"></i>
                        </div> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-3 text-start">
                    <img src={face} alt="" />
                </div> */}
                </div>
            </div>
        </div>
    );
};

export default Contact;
